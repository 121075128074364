import React, { useContext } from 'react'
import { Container, Flex } from 'theme-ui'
import { PageContextProvider } from '@helpers'
import Section from '@components/Section'
import Reveal from '@components/Reveal'

export const Stack = ({ children, effect, direction, ...props }) => {
  const context = useContext(PageContextProvider)

  const content = (
    <Container>
      <Section {...props}>
        <Flex sx={{ flexDirection: direction }}>{children}</Flex>
      </Section>
    </Container>
  )

  //Use Reveal animation only on route update
  return context.location &&
    context.location.action === 'PUSH' &&
    effect !== false ? (
    <Reveal effect={effect}>{content}</Reveal>
  ) : (
    content
  )
}
